'use-client';

import useScreen from 'hooks/useScreen';
import PropTypes from 'prop-types';

const MobileOnlyLegacy = ({ children, fallback = null }) => {
  const { isMobile } = useScreen();

  return isMobile ? children : fallback;
};

MobileOnlyLegacy.propTypes = {
  children: PropTypes.node,
};

export default MobileOnlyLegacy;
